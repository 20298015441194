import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import GuessBoxAdminStoreType from "@/views/Other/GuessBoxAdmin/Store/indexType.js";

@Module({
    name:"GuessBoxAdmin",
    store,
    dynamic: true,
})
export default class GuessBoxAdmin extends VuexModule implements GuessBoxAdminStoreType{
    GuessBoxAdminData: any={};
    get getGuessBoxAdminData(){ return this.GuessBoxAdminData }
    @Mutation
    SetGuessBoxAdminData(data: any): void {
        this.GuessBoxAdminData = data
    }

    GuessBoxAdminList: any[] = [];
    get getGuessBoxAdminList(){ return this.GuessBoxAdminList }
    @Mutation
    SetGuessBoxAdminList(data: any[]): void {
        this.GuessBoxAdminList = data
    }

}

export const GuessBoxAdminStore = getModule( GuessBoxAdmin )
