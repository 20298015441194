import Storage from "@/util/Storage"
import {createGame, getJoinList, getPresentPlays, joinGuessBoxSuc, updateGame} from '@/Api/Other';
import {GuessBoxAdminStore} from "@/views/Other/GuessBoxAdmin/Store";
import {updateGameType} from "@/Api/Other/indexType";
import {Toast} from "vant";


// 获取当前 游戏信息
export function getPlaySuc(gameId:string|number="",boxId?:string|number) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    let upData:any = {gameId}
    if ( boxId )upData["gameId"] = gameId;
    return Promise.resolve(joinGuessBoxSuc({ userId,token },upData).then(res=>{
        if (res.data) GuessBoxAdminStore.SetGuessBoxAdminData(res.data);
        return res.data
    }))
}

// 创建 游戏
export function createPlays(remark= "") {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(createGame({ userId,token },{ remark }).then(res=>{
        return res.data
    }))
}

// 修改 游戏 状态
export function updatePlayStatus(body:updateGameType) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    Toast.loading({ message:"修改中~~",duration:0 })
    return Promise.resolve(updateGame({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))
}

// 获取参加 盒子里面的人数
export function getJoinPlaysNum(gameId:string|number="") {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(getJoinList({ userId,token },{ gameId }).then(res=>{
        if (res.data) GuessBoxAdminStore.SetGuessBoxAdminList(res.data || []);
        return res.data
    }))
}

// 获取当前 游戏
export function getPlayData(bool=false) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    if ( bool ) Toast.loading({ duration:0,message:"刷新中~~~" })
    return Promise.resolve(getPresentPlays({ userId,token }).then(res=>{
        if ( bool ) Toast.clear();
        if (res.data) {
            let { id = "" } = res.data
            getPlaySuc(id).then(res=>res)
            getJoinPlaysNum(id).then(res=>res)
        }
        return res.data
    }))
}
